import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
//import { DayByDayPrice } from 'app/model/DayByDayPrice';

import { DayByDayPrice } from "../../model/DayByDayPrice";
import { AppService } from "../../app.service";
import { DecimalPipe } from "@angular/common";
import { WindowRefService } from "../../window-ref.service";
//import { AppService } from "src/app/app.service";
import { ThrowStmt } from "@angular/compiler";
import { Router } from "@angular/router";
import { StaticUtilities } from "../../shared/static-utilities";
import { Invoice } from "../../model/invoice";
//import { ViewChild } from '@angular/core';
//import { StaticUtilities } from 'src/app/shared/static-utilities';
//import { StaticUtilities } from 'src/shared/static-utilities';
declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");

@Component({
  selector: "order-modal-component",
  templateUrl: "./modal-order.html",
  providers: [WindowRefService],
})
export class OrderNgbdModalBasic implements OnInit,AfterViewInit {
  closeResult: string;
  invoice: Invoice[];
  updateAmount: number;
  successmessage: boolean = false;
  returnValidate: boolean = true;
  paymentSuccessMessage = "Paid";
  paymentFailureMessage = "Not Paid";
  pincode: string = "";
  public qrdata: string = "abcd";
  public level: "L" | "M" | "Q" | "H";
  public width: number;
  // price:DayByDayPrice;
  @Input() priceperunit: number;
  @Input() itemName: string;
  @Input() itemId: number;
  @ViewChild('content1' ,{static: true, read: ElementRef}) content: ElementRef;
paymentId:any;
  name: string = "";
  price: any[];
  public data = [
    "Abasbari(721636)",
    "Bargabhima(721636)",
    "Padumbasan(721636)",
    "Parbatipur(721636)",
    "Salgechia(721636)",
    "Sankarara(721636)",
    "Tamluk(721636)",
    "Bahichard(721627)",
    "Kelomal(721627)",
    "Radhaballavpur(721627)",
    "Teghorijoteviram(721627)",
    "Bainchberia(721649)",
    "Chakjiandigi(721649)",
    "Hijalberia(721649)",
    "Nakibasan(721649)",
    "Simulia(721649)",
    "kakgechia(721649)",
    "Kulberia(721649)",
    "Kurpai(721649)",
    "Chanserpur(721653)",
    "Salikagarchack(721653)",
    "Harasankar(721151)",
    "Haridaspur(721653)",
    "Naikuri(721630)",
  ];
  public datakg = [
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
  ];

  daybyDayPrice: DayByDayPrice;
  successfullmessage: boolean = false;
  loading = true;
  error: any;
  mission;
  itemKg: number = 0;
  constructor(
    private modalService: NgbModal,
    private winRef: WindowRefService,
    private appservice: AppService,
    private router: Router
  ) {
    this.level = "M";
    this.qrdata = "1234";
    this.width = 256;
  }

  changeLevel(newValue: "L" | "M" | "Q" | "H"): void {
    this.level = newValue;
  }
  ngAfterViewInit() {
    console.log(this.content.nativeElement); // throws an error
  }
  changeQrdata(newValue: string): void {
    this.qrdata = newValue;
  }

  changeWidth(newValue: number): void {
    this.width = newValue;
  }
  ngOnInit() {
    this.updateAmount = this.priceperunit;
    //this.paymentId="pay_FnS1ZTrjM8kPi6";  
    this.successmessage = false;

    const requestJson: JSON = <JSON>(<unknown>{
      razorpay_payment_id: "pay_FnS1ZTrjM8kPi6",
    });
    this.appservice.getInvoiceDetails(requestJson).subscribe(            
      data => {    
      //  debugger;           
        this.invoice = data;
      },
      error => {//alert('Error!');
    }
  );
    //this.getlastData();
  }
  updatePrice(data: any) {
    this.itemKg = parseInt(data.target.value);
    this.updateAmount = this.priceperunit * parseInt(data.target.value);
  }
  createRzpayOrder(data) {
  //  debugger;
    this.returnValidate = true;
    if (this.validate()) {
      this.payWithRazor();
    }
  }
  validate() {
    if (this.itemKg < 10) {
      this.returnValidate = false;
      alert("Minimum order required 10 kg");
    }
    if (this.name == "" || this.name == "Undefined") {
      this.returnValidate = false;
      alert("Name Required");
    }
    if (this.name == "" || this.name == "Undefined") {
      this.returnValidate = false;
      alert("Name Required");
    }
    if (this.pincode == "" || this.pincode == "Undefined") {
      this.returnValidate = false;
      alert("Pincode required");
    }
    return this.returnValidate;
  }
  changepincode(data: any) {
    this.pincode = data.target.value;
  }

  onExportPdf() {
    let content=this.winRef.nativeWindow.content;
    let doc = new jsPDF();
    let _elementHandlers =
    {
      '#editor':function(element,renderer){
        return true;
      }
    };
    doc.fromHTML(content.innerHTML,15,15,{

      'width':190,
      'elementHandlers':_elementHandlers
    });
    // console.log("pdf");
    // const doc = new jsPDF();
    // //     const img = new Image();

    // const pageHeight = doc.internal.pageSize.height;
    // const pageWidth = doc.internal.pageSize.width;

    // //     const thisConst = this;

    // //     img.onload = function() {
    // //       doc.addImage(this, 10, 10, 25, 25);

    // doc.setFontSize(14);
    // doc.text(
    //   "APANJAN MEATSHOP",
    //   StaticUtilities.pdfTextCenter(doc, "APANJAN MEATSHOP", 14),
    //   15
    // );

    doc.save("onlineinvoiceapanjanmeatshop.pdf");
  }

  payWithRazor() {
    this.successmessage = false;
    this.appservice.succesMessage = "";
    const namedetails ="ApanjanMeatshop"+
      "Name:  " +
      this.name +
      ",Name:" +
      this.itemName +
      "," +
      this.itemKg +
      " Kg" +
      ",Address: " +
      this.pincode;
    const options: any = {
      key: "rzp_live_ZlyugNFw3gxUdW",
     // amount: 2 * 100, // amount should be in paise format to display Rs 1255 without decimal point
      amount: this.updateAmount * 100,
      currency: "INR",
      name: "Apanjan Meat Shop", // company name or product name concat korte hbe name, pincode, kg ,item name
      description: this.itemName, // product description
      image: "./assets/img/logo1.png", // company logo or product image
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: "#F37254",
      },
    };
    options.handler = (response, error) => {
      options.response = response;
     // console.log(response);
     // console.log(options);
      if (response != null) {
        this.successmessage = true;
      }
      let reqData = {
        razorpay_payment_id: response.razorpay_payment_id,
        totalAmount: this.updateAmount,
        product_id: this.itemId,
        product_name: namedetails,
      };
      const requestJson: JSON = <JSON>(<unknown>{
        razorpay_payment_id: response.razorpay_payment_id,
      });
      this.appservice.saveOrder(reqData).subscribe((res) => {
        if (this.appservice.succesMessage != "" && this.successmessage) { 
          this.appservice.getInvoiceDetails(requestJson).subscribe(            
            data => {   
              this.paymentId=  response.razorpay_payment_id;          
              this.invoice = data;
            },
            error => {alert('Error!');
          }
        );
          
        }
      });

      // call your backend api to verify payment signature & capture transaction
    };
    options.modal.ondismiss = () => {
      // handle the case when user closes the form while transaction is in progress
      console.log("Transaction cancelled.");
    };
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  goBack() {
   // debugger;
    this.successmessage = true;
  }

  open(content, type, modalDimension) {
    if (modalDimension === "sm" && type === "modal_mini") {
      this.modalService
        .open(content, { windowClass: "modal-login modal-primary" })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    } else if (modalDimension == undefined && type === "Login") {
      this.modalService
        .open(content, { windowClass: "modal-login modal-primary" })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    } else {
      this.modalService.open(content).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
