import { Component, OnInit, Input } from "@angular/core";
//import { DayByDayPrice } from 'src/app/model/DayByDayPrice';
//import { DayByDayPrice } from 'app/model/DayByDayPrice';
//import { AppService } from 'app/app.service';

import { DayByDayPrice } from "../../model/DayByDayPrice";
import { AppService } from "../../app.service";

//import { ItemDetails } from 'src/app/model/item';
//import { DayByDayPrice } from 'src/app/model/DayByDayPrice';
@Component({
  selector: "app-typography",
  templateUrl: "./typography.component.html",
  styleUrls: ["./typography.component.scss"],
})
export class TypographyComponent implements OnInit {
  price: any[];
  daybyDayPrice: DayByDayPrice;
  data:any;
  successfullmessage: boolean = false;
  loading = true;
  error: any;
  constructor(private appService: AppService) {}

  ngOnInit() {
    this.getlastData();
     this.data={  BONELESSMEAT : "BONELESS MEAT",
    CHICKENKEEMA:"CHICKEN KEEMA",
    SHREDDEDCHICKEN: "SHREDDED CHICKEN",
    CHILIGARLICCHICKENPIECE:  "CHILI / GARLIC CHICKEN PIECE",
    CHICKENTANGRI:  "CHICKEN TANGRI",
    SLICEDCHICKEN:  "SLICED CHICKEN",
    CHICKENWINGS:  "CHICKEN WINGS",
    FORCHICKENKASSA: "FOR CHICKEN KASSA"};
  }
  getlastData(): void {
    this.appService.getPrice().subscribe(
      (res) => {
        this.daybyDayPrice = res;
      },
      (err) => {
        this.error = err;
      }
    );
  }
}
