import { Component, OnInit } from '@angular/core';
import { User } from '../../model/user';
import { AppService } from '../../app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    data : Date = new Date();
    focus;
    focus1;
    model= new User();

    constructor( private router: Router,
        private priceService: AppService
        ) { }
    addInfo(){ // debugger; 
         this.priceService
          .addUserInfo(this.model)
          .subscribe(()=> this.goMenu());
    }
     goMenu(){
      this.router.navigate(['/price']);
    }
    ngOnInit() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('login-page');

        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
    }
    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('login-page');

        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
    }

}
