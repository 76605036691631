export class DayByDayPrice {   
        pricebroiler: string;
        priceRodail: number;	
         pricebonelessmeat: string;
         pricechickenkeema:  string;
         pricechickenshredded:  string;
         pricechickengarlic:  string;
         pricechickentangri:  string;
         pricechickenslice:  string;
         pricechickenwings:  string;
         pricechickenkassa:  string;
         priceKalBirds:  string;
         priceLayerBirds:  string;
         price_kal_birds:string;
         price_layer_birds:string;
         price_rodail:string;
       
 
 
}
