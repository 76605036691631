import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { ExamplesModule } from './examples/examples.module';
import { NguiMapModule} from '@ngui/map';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { MapsComponent } from './maps/maps.component';
import { ContactComponent } from './contact/contact.component';
import { AppService } from './app.service';
import { HttpModule } from '@angular/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { MeatShopComponent } from './meatshop-price/meat-shop-price.component';
import { OrderDetailsComponent } from './meatshop-orders/meat-shop-order-details';
import { AdminNavbarComponent } from './shared/adminnavbar/admin-navbar.component';
import { WindowRefService } from './window-ref.service';
import { QRCodeModule } from 'angular2-qrcode';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { MenuComponent } from './menu/menu.component';
@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        AdminNavbarComponent,
        MapsComponent,
        ContactComponent,
        MeatShopComponent,
        MenuComponent,
        OrderDetailsComponent//,
        //InvoiceComponent
    ],
    imports: [
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        HttpModule,
        HttpClientModule,
       // QRCodeModule,
        ComponentsModule,
        ExamplesModule,
        NguiMapModule.forRoot({apiUrl: 'https://maps.google.com/maps/api/js?key=AIzaSyAP1-X0n_-Ncb29u7vHx0IrmKaokSw4Iss'})
    ],
    providers: [AppService,WindowRefService,{provide:LocationStrategy,useClass:HashLocationStrategy}],
    bootstrap: [AppComponent]
})
export class AppModule { }
