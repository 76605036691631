import{Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core'
import { Router } from '@angular/router';
import { Invoice } from '../../model/invoice';
import { AppService } from '../../app.service';
//import { AppService } from '../app.service';
//import { Invoice } from '../model/invoice';
//import { OrderDetails } from '../model/orderdetails';
declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");

@Component({
    selector: 'invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit{
   
    public data=['Open','Processed','Delivered'];
    invoice: Invoice[];
    @Input() paymentId:any;
    @Input() invoicedata : Invoice[];
    productName:string;
    method:string;
    email:string;
    mobileNo:string;
    amount:string;
    paymentiddtls:string;
    date:string;
  @ViewChild('content1' ,{static: true, read: ElementRef}) content: ElementRef;

    constructor(private service: AppService,  private router: Router ){

    }
    onExportPdf() {
        let content=this.content.nativeElement;
        let doc = new jsPDF();
        let _elementHandlers =
        {
          '#editor':function(element,renderer){
            return true;
          }
        };
        doc.fromHTML(content.innerHTML,15,15,{
    
          'width':190,
          'elementHandlers':_elementHandlers
        });
        // console.log("pdf");
        // const doc = new jsPDF();
        // //     const img = new Image();
    
        // const pageHeight = doc.internal.pageSize.height;
        // const pageWidth = doc.internal.pageSize.width;
    
        // //     const thisConst = this;
    
        // //     img.onload = function() {
        // //       doc.addImage(this, 10, 10, 25, 25);
    
        // doc.setFontSize(14);
        // doc.text(
        //   "APANJAN MEATSHOP",
        //   StaticUtilities.pdfTextCenter(doc, "APANJAN MEATSHOP", 14),
        //   15
        // );
    
        doc.save("onlineinvoiceapanjanmeatshop.pdf");
      }
    
    ngOnInit(){       
    //     this.invoicedata.forEach(function (value) {
    //        this. productName=value.product_name;        
    // this.method=value.method;
    // this. email=value.email;
    // this. mobileNo= value.mobileNo;
    // this. amount=value.amount;
    // this. paymentiddtls=value.payment_id
    // this. date=value.created_date;
 
  
    //         console.log(value);
    //       }); 
    }
     
goBack(){
 this.router.navigate(['/orderDetails']);
}
}