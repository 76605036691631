import { Component, OnInit } from '@angular/core';
import * as Rellax from 'rellax';
import { AppService } from '../../app.service';
import { DayByDayPrice } from '../../model/DayByDayPrice';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  data : Date = new Date();
  focus;
  focus1;
 // dayByDayPrice=new DayByDayPrice();

  constructor(private appService: AppService ) { }

  ngOnInit() {
    dayByDayPrice: DayByDayPrice;
    // this.appService
    // .getPrice()
    // .subscribe(dayByDayPrice =>{                 
    //   this.dayByDayPrice=dayByDayPrice[0];                
    //     })
    var rellaxHeader = new Rellax('.rellax-header');

    var body = document.getElementsByTagName('body')[0];
    body.classList.add('landing-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
  }
  ngOnDestroy(){
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('landing-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');
  }
}
