import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../app.service";
import { Invoice } from "../model/invoice";
import { OrderDetails } from "../model/orderdetails";
declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");

@Component({
  selector: "order-details",
  templateUrl: "./meat-shop-order-details.html",
  styleUrls: ["./meat-shop-order-details.scss"],
})
export class OrderDetailsComponent implements OnInit {
  public orderDetails: Array<OrderDetails> = [];
  public data = ["Open", "Processed", "Delivered"];
  invoice: Invoice[];
  @ViewChild("content1", { static: true, read: ElementRef })
  content: ElementRef;

  constructor(private service: AppService, private router: Router) {}
  ngOnInit() {
    this.service.getOrderDetails().subscribe(
      (data) => {
        this.orderDetails = data;
      },
      (error) => {
        alert("Error!");
      }
    );
  }
  updatedetails(id, status) {
    const requestJson: JSON = <JSON>(<unknown>{
      ID: id,
      STATUS: status,
    });
    this.service.updateOrderDetails(requestJson).subscribe(() => this.goBack());
  }

  goBack() {
    this.router.navigate(["/orderDetails"]);
  }
}
