/*!

=========================================================
* Now UI Kit Angular - v1.1.0
=========================================================

* Product Page: https://www.far-tech.co.in/product/fartech
* Copyright 2019 fartech (https://www.far-tech.co.in)
* Licensed under MIT (https://github.com/creativetimofficial/fartech/blob/master/LICENSE.md)

* Coded by fartech

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
