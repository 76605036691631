import { Component, OnInit } from '@angular/core';


import {ActivatedRoute, Params, Router} from '@angular/router';
import { DayByDayPrice } from '../model/DayByDayPrice';
import { AppService } from '../app.service';
@Component({
    selector: 'app-meat-shop-price',
    templateUrl: './meat-shop-price.component.html',
    styleUrls: ['./meat-shop-price.component.scss']
})

export class MeatShopComponent implements OnInit {
  data : Date = new Date();
  price: any[];
  priceDiv:boolean;
  daybyDayPrice: DayByDayPrice;
  successfullmessage:boolean=false;
   loading = true;
   error: any;
constructor( private priceService: AppService,
 
     private router: Router ) { }

  ngOnInit() {
  if(this.priceService.loginInfo==1)
   this.getlastData();
   else 
  { this.daybyDayPrice=null;
    this.priceDiv=false;
  }
}

getlastData(): void {
  this.priceService.getPrice().subscribe(
    (res) => {
      this.daybyDayPrice = res;
      this.priceDiv=true;
    },
    (err) => {
      this.error = err;
    }
  );
}
setData(price){
this.daybyDayPrice=price;
console.log('class data '+this.daybyDayPrice);
}

  addInfo(){  
   
     this.priceService
       .updatePrice(this.daybyDayPrice)
        .subscribe(()=> this.goBack());
  }
   goBack(){
    this.router.navigate(['/home']);
  }
}
