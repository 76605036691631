import { Injectable } from '@angular/core';
import { Http,  RequestMethod, RequestOptions } from '@angular/http';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
//import 'rxjs/add/operator/map';
import { JsonPipe } from '@angular/common';
import { DayByDayPrice } from './model/DayByDayPrice';
import { map, catchError } from 'rxjs/operators';
import {throwError } from 'rxjs';
//import { Apollo } from 'apollo-angular';
//import gql from "graphql-tag";
import { Observable } from 'rxjs/internal/Observable';
//import { OrderDetails } from './model/orderdetails';
import { OrderDetails } from './model/orderdetails';
import { Invoice } from './model/invoice';
@Injectable({
    providedIn: 'root'
  })
export class AppService {
  price: any[];
  daybyDayPrice: DayByDayPrice;
  orderDetails : OrderDetails;
  invoice:Invoice;
 // successfullmessage:boolean=false;
   loading = true;
   error: any;
   lastID:any;
   loginInfo:any;
   menuShow:boolean=false;
   succesMessage:any;
  // employees=[];
  constructor(private _http: HttpClient) { }
  getPrice(){
    //debugger;
    return this._http.get("https://apanjanmeatshop.com/api/getPrices").pipe(
      map((res)=>{
        this.daybyDayPrice = res[0];
        return this.daybyDayPrice;
       
      }),
      catchError(this.handleError));
  }
  updatePrice(info){   
   return this._http.post("https://apanjanmeatshop.com/api/savePrice",info).pipe(map((res)=>""));
  }
  saveContactInfo(info){
    return this._http.post("https://apanjanmeatshop.com/api/saveContactInfo",info).pipe(map((res)=>""));
  }
  saveOrder(info){   
    return this._http.post("https://apanjanmeatshop.com/api/saveOrders",info).pipe(map((res)=>{
     // debugger;
     this.lastID=res;
     this.succesMessage='Order Save Successfully';
    }));
   }
 
  updateOrderDetails(requestJson){   
    
    return this._http.post("https://apanjanmeatshop.com/api/UpdateOrderStatus",requestJson).pipe(map((res)=>""));
   }
   getInvoiceDetails(requestJson) :Observable<Invoice[]>{      
    return this._http.post("https://apanjanmeatshop.com/api/fetchPaymentDtlById",requestJson).pipe(
      map((res: any)=> res as Invoice[]),      
      catchError(this.handleError));
  }
  getOrderDetails() :Observable<OrderDetails[]>{
    //debugger;
    return this._http.get("https://apanjanmeatshop.com/api/getOrders").pipe(
      map((res: any)=> res as OrderDetails[]),
      
      catchError(this.handleError));
  }
  addStudentInfo(info){ 
  
    // return this._http.post("http://www.far-tech.co.in/api/insert.php",info)
     // .map(()=>"");
   }
  addUserInfo(info){ 
  
    return this._http.post("https://apanjanmeatshop.com/api/authlogin",info).pipe(map((res)=>{
      // debugger;
      this.loginInfo=res;
      this.menuShow=true;
     
     }));
  }
  getStudent(id){
    //return this._http.post("http://www.far-tech.co.in/api/selectone.php/",{'id':id})
     // .map(res=>res.json());
  }
  deleteStudent(id){
    //return this._http.post("http://www.far-tech.co.in/api/delete.php/",{'id':id})
     // .map(()=>this.getPrice());
  }
  updateStudent(info){
   // return this._http.post("http://www.far-tech.co.in/api/update.php/", info)
    //  .map(()=>"");
  }
  private handleError(error: HttpErrorResponse) {
    console.log(error);
    // return an observable with a user friendly message
    return throwError('Error! something went wrong.');
  }
}
