import { Component, OnInit } from '@angular/core';
//import { StudentService } from '../student.service';
//import { Student } from '../student';

import {ActivatedRoute, Params, Router} from '@angular/router';
import { AppService } from '../app.service';
import { contactus } from '../model/contactus';
@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {
  data : Date = new Date();
  contactus=new contactus();
constructor( private priceService: AppService,
     private router: Router ) { }

  ngOnInit() {

    this.contactus.name="";
    this.contactus.email="";
    this.contactus.message="";
    this.contactus.phonenumber=0;

  }
//model = new Student();
  addInfo(){   
    this.contactus.product_name="Name :" +this.contactus.name + ",email :"+ this.contactus.email +",ph. No."+this.contactus.phonenumber + ",is contacting for:" + this.contactus.message;
    this.priceService
    .saveContactInfo(this.contactus)
     .subscribe(()=> this.goBack());
}
goBack(){
  alert("Thanks for contacting us!!! ..we will reach you soon");
 this.router.navigate(['/home']);
}
}
